import React, { Fragment } from 'react';
import { animated } from '@react-spring/web';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Avatar, CardHeader, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { red } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../../store/userSlice';
import documentIcon from '../../../../assets/icons/documentIcon.png';

function DocumentsBox({
  open,
  setOpen,
  productId,
  springs,
  filePathes
}) {
  const user = useSelector(userSelector);

  const card = (
    <Fragment>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {user.nom.charAt(0).toUpperCase()}
          </Avatar>
        }
        action={
          <IconButton onClick={() => setOpen(false)}>
            <ClearIcon />
          </IconButton>
        }
        title={`${user.nom} ${user.prenom}`}
        subheader={productId}
      />
      <CardContent>
        {filePathes && filePathes.map((path) => {
          return (
            <a key={path} href={path} target='_blank' rel="noopener noreferrer" className='flex items-center mb-3'>
              <img src={documentIcon} className='mr-3'/>
              {path.split('/').pop()}
            </a>
          );
        })}
      </CardContent>
    </Fragment>
  );

  return (
    open && (
      <animated.div
        className='min-w-80 z-20 fixed bottom-0 right-0 bg-gray-200'
        style={{
          ...springs
        }}
      >
        <Card sx={{height: 'auto'}} variant="outlined">{card}</Card>
      </animated.div>
    )
  );
}

export default DocumentsBox;
