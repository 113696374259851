import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button, createTheme, Grid, TextField, ThemeProvider } from '@mui/material';
import { getLang } from '../../../../../environnement';

export default function AddClientDialog({
  onClose,
  open,
  onClickAddClient,
  errors,
}) {
  const [lang] = useState('FR');

  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    setNom('');
    setPrenom('');
    setEmail('');
    setPhone('');
    setBirthDate('');
    setPassword('');
    setConfirmPassword('');
  }, [open]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} fullWidth>
        <DialogTitle>{getLang(lang).clients.addClientsDialog.addNewClient}</DialogTitle>
        <Grid container spacing={2}>
          {/* nom */}
          <Grid item xs={6}>
            <TextField
              id="nom"
              label={getLang(lang).clients.addClientsDialog.lastName}
              variant="outlined"
              margin='dense'
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              error={errors && errors.nom}
              helperText={errors && errors.nom ? errors.nom : ''}
              fullWidth
            />
          </Grid>
          {/* prénom */}
          <Grid item xs={6}>
            <TextField
              id="prenom"
              label={getLang(lang).clients.addClientsDialog.firstName}
              variant="outlined"
              margin='dense'
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              error={errors && errors.prenom}
              helperText={errors && errors.prenom ? errors.prenom : ''}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* email */}
          <Grid item xs={6}>
            <TextField
              id="email"
              type='email'
              label={getLang(lang).clients.addClientsDialog.email}
              variant="outlined"
              margin='dense'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={errors && errors.email}
              helperText={errors && errors.email ? errors.email : ''}
              fullWidth
            />
          </Grid>
          {/* phone */}
          <Grid item xs={6}>
            <TextField
              id="phone"
              type='tel'
              label={getLang(lang).clients.addClientsDialog.phone}
              variant="outlined"
              margin='dense'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              error={errors && errors.phone}
              helperText={errors && errors.phone ? errors.phone : ''}
              fullWidth
            />
          </Grid>
        </Grid>
        {/* birthDate */}
        <Grid container>
          <TextField
            id="birthDate"
            type='date'
            label={getLang(lang).clients.addClientsDialog.birthDate}
            variant="outlined"
            margin='dense'
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            error={errors && errors.birthDate}
            helperText={errors && errors.birthDate ? errors.birthDate : ''}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid container spacing={2}>
          {/* password */}
          <Grid item xs={6}>
            <TextField
              id="password"
              type='password'
              label={getLang(lang).clients.addClientsDialog.password}
              variant="outlined"
              margin='dense'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={errors && errors.password}
              helperText={errors && errors.password ? errors.password : ''}
              fullWidth
            />
          </Grid>
          {/* confirmPassword */}
          <Grid item xs={6}>
            <TextField
              id="confirmPassword"
              type='password'
              label={getLang(lang).clients.addClientsDialog.confirmPassword}
              variant="outlined"
              margin='dense'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={errors && errors.password_confirmation}
              helperText={errors && errors.password_confirmation ? errors.password_confirmation : ''}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant='contained'
              size='large'
              onClick={onClose}
              fullWidth
            >
              {getLang(lang).clients.addClientsDialog.cancel}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant='contained'
              size='large'
              onClick={() => onClickAddClient({
                nom,
                prenom,
                email,
                phone,
                birthDate,
                password,
                password_confirmation: confirmPassword
              })}
              fullWidth
            >
              {getLang(lang).clients.addClientsDialog.add}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
}

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '20px',
          paddingTop: '10px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingLeft: '5px',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: '10px',
        }
      }
    }
  },
});