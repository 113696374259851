import React, { useState } from 'react';
import { useSpring } from '@react-spring/web';
import { IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import axios from 'axios';
import deleteIcon from '../../../assets/icons/deleteIcon.png';
import documentIcon from '../../../assets/icons/documentIcon.png';
import productIdIcon from '../../../assets/icons/productIdIcon.png';
import DocumentsBox from './documentsBox';
import getEnvironement from '../../../environnement';
import { setUser, userSelector } from '../../../store/userSlice';

function RecordsTable({
  header,
  data,
}) {
  const [open, setOpen] = useState(false);
  const [activeProduct, setActiveProduct] = useState('');
  const [filePathes, setFilePathes] = useState([]);

  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const [springs, api] = useSpring(() => ({
    from: { transform: 'translateY(100%)' }
  }));

  const getStatus = (status) => {
    return status.map((item, index) => {
      if (item.status === 'Rejetée') {
        return (
          <p key={index} className='rounded-full bg-[#FDB9BC] font-poppins font-normal text-sm/[16px] text-center py-1 m-1 text-[#F04148]'>Rejetée</p>
        );
      } else if (item.status === 'Validée') {
        return (
          <p key={index} className='rounded-full bg-[#CAFFBD] font-poppins font-normal text-sm/[16px] text-center py-1 m-1 text-[#35DE0A]'>Validée</p>
        );
      } else {
        return (
          <Tooltip key={index} title={item.status}>
            <p className='rounded-full bg-[#E5E5E5] font-poppins font-normal text-sm/[16px] text-center py-1 m-1 text-[#000000]'>{`${item.status.split(' ')[0]} ${item.status.split(' ')[1]} ...`}</p>
          </Tooltip>
        );
      }
    });
  };

  const isDemandeAnnulation = (status) => {
    let isDemandeAnnulation = false;
    status.forEach(element => {
      if (element.status === 'Validée') {
        isDemandeAnnulation = true;
        return;
      }
    });
    return isDemandeAnnulation;
  };

  const extractFileNames = (products) => {
    return products.map(product => {
      if (product.documents && product.documents.length > 0) {
        let fileNames = product.documents.map(doc => doc.file_name);
        return {
          product_id: product.product_id,
          file_names: fileNames
        };
      } else {
        return {
          product_id: product.product_id,
          file_names: []
        };
      }
    });
  };

  const getFilePathesByProductId = (productsData, productId) => {
    let product = productsData.find(product => product.product_id === productId);

    if (product) {
      return product.file_names.map((fileName) => `${getEnvironement().BACKEND_URL}/storage/pdfs/${fileName}`);
    } else {
      return [];
    }
  };

  const handelClickDocuments = (user, productId) => {
    setActiveProduct(productId);
    api.start({
      from: { transform: 'translateY(100%)' },
      to: { transform: 'translateY(0)' },
      config: { tension: 300, friction: 20 }
    });
    let productsFilesData = extractFileNames(user.products);
    let filePaths = getFilePathesByProductId(productsFilesData, productId);
    setFilePathes(filePaths);
    setOpen(true);
  };

  const getProductName = (product_id) => {
    const productsName = {
      'CE': 'entreprise',
      'CS': 'monStatus',
      'CA': 'associations',
      'CAE': 'autoEntreprise',
      'CG': 'carteGrise',
      'CHS': 'changementStatut',
      'C': 'cession',
      'D': 'dissolution',
    };
    return productsName[product_id.split('-')[0]];
  };

  const deleteProduct = async (product_id, isDemandeAnnulation) => {
    const url = `${getEnvironement().API_URL}/${getProductName(product_id)}/${product_id}`;
    const data = {
      user_id: user.id,
      isDemandeAnnulation,
    };
    await axios.delete(url, {data})
      .then((response) => {
        if (response.status === 200) {
          dispatch(setUser(response.data));
        }
      });
  };

  return (
    <>
      <DocumentsBox
        open={open}
        setOpen={setOpen}
        springs={springs}
        productId={activeProduct}
        filePathes={filePathes}
      />
      <table className='table-auto w-full'>
        <thead>
          <tr className='border-solid border-b border-[#EBEBEB]'>
            {header.map((item, index) => {
              return (
                <th 
                  key={item}
                  className={`font-poppins font-medium text-sm/[16px] opacity-50 py-5 ${index === 0 && 'text-start w-24'}`}
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr key={index} className='border-solid border-b border-[#EBEBEB]'>
                <td className='min-w-32 md:min-w-none flex py-2 items-center font-poppins font-normal text-sm/[16px]'>
                  <img src={productIdIcon} />
                  <p className='ml-3 max-w-14 break-words'>{item.product_id}</p>
                </td>
                <td className='font-poppins font-normal text-sm/[16px] text-center opacity-50'>{item.type}</td>
                <td className='font-poppins font-normal text-sm/[16px] text-center'>{format(new Date(item.created_at), 'yyyy-MM-dd HH:mm')}</td>
                <td>{getStatus(item.status)}</td>
                <td className='py-5 text-center'>
                  <Tooltip title='Documents'>
                    <IconButton onClick={() => handelClickDocuments(user, item.product_id)}>
                      <img src={documentIcon} />
                    </IconButton>
                  </Tooltip>
                </td>
                <td className='py-5 text-center'>
                  <Tooltip title={isDemandeAnnulation(item.status) ? 'Demande d\'annulation' : 'Annuler'}>
                    <IconButton onClick={() => deleteProduct(item.product_id, isDemandeAnnulation(item.status))}>
                      <img src={deleteIcon} />
                    </IconButton>
                  </Tooltip>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default RecordsTable;