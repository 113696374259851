import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/userSlice';
import DashHeader from './components/dashHeader';
import userprofile from '../../assets/images/userprofile.jpg';
import emailicon from '../../assets/icons/email.png';
import CustomTextInput from '../../components/inputs/customTextInput/customTextInput';
import { Button } from '@mui/material';

function Contact() {
  const [email, setEmail] = useState('');
  const user = useSelector(userSelector);

  return (
    <>
      <DashHeader picture={userprofile} name={user?.nom} />
      <div className='w-full lg:w-1/3'>
        <p className='mt-8 mb-2'>Email</p>
        <CustomTextInput 
          value={email}
          setValue={setEmail}
          placeholder="Email"
          image={emailicon} />
      </div>
      <div className='w-full lg:w-1/2'>
        <p className='mt-8 mb-2'>Message</p>
        <textarea name="" className='bg-lightgrayv2 w-full h-60 resize-none mb-4' id=""></textarea>
      </div>
      <Button variant='contained' className='mt-4'>Envoyer</Button>
    </>
  );
}

export default Contact;