import React from 'react';
import HeaderContainer from '../../components/headerContainer/headerContainer';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import getEnvironement from '../../environnement';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/userSlice';

export default function Paiement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {productType, userId, packId, data} = location.state || {};

  const saveData = async (packId) => {
    const url = `${getEnvironement().API_URL}/${productType}`;

    console.log(data.pdfBlob);
    
    const formData = new FormData();
    for (const key in data) {
      if (Array.isArray(data[key]) || typeof data[key] === 'object') {
        formData.append(key, JSON.stringify(data[key]));
      } else {
        formData.append(key, data[key]);
      }
    }
    if (formData.has('pdf')) {
      formData.delete('pdf');
      formData.append('pdf', data.pdfBlob, 'document.pdf');
    }
    formData.delete('packId');
    formData.append('packId', packId);
  
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      await axios.post(url, formData, config)
        .then((response) => {
          if (response.status === 200) {
            dispatch(setUser(response.data));
            navigate('/dashboard');
          }
        });
    } catch (error) {
      console.log('Error saving data', error);
    }
  };

  const handelPiementClick = async () => {
    const url = `${getEnvironement().API_URL}/addUserPack`;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    await axios.post(url, {userId, packId}, config)
      .then((response) => saveData(response.data.id));
  };
  return (
    <>
      <HeaderContainer />
      <div className='flex justify-center mt-20'>
        <Button
          variant='contained'
          onClick={handelPiementClick}
        >
          Acheter ce pack
        </Button>
      </div>
    </>
  );
}
