import React, { useEffect, useState } from 'react';
import { Alert, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import HeaderContainer from '../../../components/headerContainer/headerContainer';
import './login.css';
import getEnvironement from '../../../environnement';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../store/userSlice';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resterConnecte, setResterConnecte] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [status, setStatus] = useState(location.state?.data || '');

  useEffect(() => {
    setTimeout(() => setStatus(''), 4000);
  }, [status]);

  const handelLogin = () => {
    const url = `${getEnvironement().API_URL}/auth/login`;
    const data = {
      email,
      password,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    axios.post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setUser(response.data.user));
          navigate('/dashboard');
        } else if (response.status === 400) {
          setStatus('error-login');
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className='login-container'>
      <HeaderContainer />
      <div className='login'>
        {status === 'succes-inscription'
          && (<Alert severity="success" className='alert'>inscription réussie, un email vous a été envoyé pour validation</Alert>)}
        {status === 'error-login'
          && (<Alert severity="error" className='alert'>email ou mot de passe incorrécte</Alert>)}
        <div className='login-form'>
          <p className='title'>Connexion:</p>
          <label htmlFor='email' className='email'>
            Email:
            <TextField
              id="email"
              variant="outlined"
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label htmlFor='password' className='password'>
            Mot de passe:
            <TextField
              id="password"
              variant="outlined"
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={resterConnecte}
                onChange={(e) => setResterConnecte(e.target.checked)}
              />
            }
            label="Rester connecté"
            labelPlacement="end"
            className='resterConnecte'
          />
          <Button
            className='login-button'
            variant='contained'
            onClick={handelLogin}
          >
            Se connecter
          </Button>
          <div className='login-link-container'>
            <Link to={'#'}>Mot de passe oublié ?</Link>
            <Link to={'/inscription'}>Créer un nouveau compte</Link>
          </div>
        </div>
      </div>
      
    </div>
  );
}
