import React, { useRef, useState } from 'react';
import DashHeader from './components/dashHeader';
import CustomTextInput from '../../components/inputs/customTextInput/customTextInput';
import usericon from '../../assets/icons/usericon.png';
import bday from '../../assets/icons/bday.png';
import phone from '../../assets/icons/phone.png';
import emailicon from '../../assets/icons/email.png';
import password from '../../assets/icons/passicon.png';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, userSelector } from '../../store/userSlice';
import getEnvironement from '../../environnement';
import axios from 'axios';
import Swal from 'sweetalert2';

const UserProfileUpdate = () => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState(user?.prenom);
  const [lastName, setLastName] = useState(user?.nom);
  const [birthDate, setBirthDate] = useState(user?.birthDate);
  const [phoneNumber, setPhoneNumber] = useState(user?.phone);
  const [email, setEmail] = useState(user?.email);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [profilePic, setProfilePic] = useState(`${getEnvironement().BACKEND_URL}/storage/` + user?.profile_picture);
  const fileInputRef = useRef(null);

  const handleUpdate = () => {

    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur!',
        text: 'Les mots de passe ne correspondent pas.',
        confirmButtonText: 'OK'
      });
      return;
    }
    
    const url = `${getEnvironement().API_URL}/updateUser`;
    const formData = new FormData();
  
    formData.append('id', user?.id);
    formData.append('nom', lastName);
    formData.append('prenom', firstName);
    formData.append('email', email);
    formData.append('phone', phoneNumber);
    formData.append('password', newPassword);
    formData.append('confirmPassword', confirmPassword);
    formData.append('birthDate', birthDate);
  
    if (fileInputRef.current.files[0]) {
      formData.append('profile_picture', fileInputRef.current.files[0]);
    }
  
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
  
    axios.post(url, formData, config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setUser(response.data.user));
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Votre compte a été mis à jour avec succès.',
            confirmButtonText: 'OK'
          });
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Une erreur s\'est produite lors de la mise à jour de votre compte. Veuillez réessayer plus tard',
          confirmButtonText: 'OK'
        });
      });
  };

  const handleProfilePicChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setProfilePic(URL.createObjectURL(file));
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };


  return (
    <div className='w-full lg:w-[600px]'>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleProfilePicChange}
        style={{ display: 'none' }}
      />
      <DashHeader picture={profilePic} name={user?.nom} onClick={triggerFileInput} size='4rem' />
      <h3 className="text-xl font-semibold mb-2">Informations sur le compte</h3>
      <p className=" w-1/2 md:w-full text-gray-600 mb-3">Mise à jour des informations relatives à votre compte</p>
      <p className='text-lg font-medium mb-4'>Personal Information</p>
      <div className='flex flex-col lg:flex-row mb-3'>
        <div className='w-full space-y-3'>
          <p>Nom</p>
          <CustomTextInput
            value={lastName}
            setValue={setLastName}
            placeholder="Nom"
            image={usericon}
          />
        </div>
        <div className='w-full space-y-3'>
          <p>Prénom</p>
          <CustomTextInput
            value={firstName}
            setValue={setFirstName}
            placeholder="Prénom"
            image={usericon}
          />
        </div>
      </div>
      <div className='flex flex-col lg:flex-row mb-3'>
        <div className='w-full space-y-3'>
          <p>Date de naissance</p>
          <CustomTextInput
            value={birthDate}
            setValue={setBirthDate}
            placeholder="Date de naissance"
            type="date"
            image={bday}
          />
        </div>
        <div className='w-full space-y-3'>
          <p>Numéro de téléphone</p>
          <CustomTextInput
            value={phoneNumber}
            setValue={setPhoneNumber}
            placeholder="Numéro de téléphone"
            image={phone}
          />
        </div>
      </div>
      <div className='space-y-3 mb-3'>
        <p>Email</p>
        <CustomTextInput
          value={email}
          setValue={setEmail}
          placeholder="Email"
          image={emailicon}
        />
      </div>
      <div className='flex flex-col lg:flex-row mb-3'>
        <div className='w-full space-y-3'>
          <p>Nouveau mot de passe</p>
          <CustomTextInput
            value={newPassword}
            setValue={setNewPassword}
            placeholder="Nouveau mot de passe"
            type="password"
            image={password}
          />
        </div>
        <div className='w-full space-y-3'>
          <p>Confirmer le mot de passe</p>
          <CustomTextInput
            value={confirmPassword}
            setValue={setConfirmPassword}
            placeholder="Confirmer le mot de passe"
            type="password"
            image={password}

          />
        </div>
      </div>
      <Button variant='contained' onClick={handleUpdate} size='small'>Update</Button>
    </div>
  );
};

export default UserProfileUpdate;