import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import { userSelector } from '../../store/userSlice';
import AdminHeader from './utils/components/header';
import axios from 'axios';
import getEnvironement from '../../environnement';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import searchIcon from '../../assets/icons/search-normal.png';
import StatusTable from './utils/components/tables/statusTable';
import AddStatusDialog from './utils/components/dialog/addStatusDialog';
import UpdateStatusDialog from './utils/components/dialog/updateStatusDialog';
import DeleteStatusConfirmDialog from './utils/components/dialog/deleteStatusConfirmDialog';

export default function AdminStatus() {
  const user = useSelector(userSelector);

  const [allStatus, setAllStatus] = useState([]);
  const [status, setStatus] = useState([]);
  const [isEmptyStatus, setIsEmptyStatus] = useState(false);

  const [openAddStatusDialog, setOpenAddStatusDialog] = useState(false);
  const [addStatusErrors, setAddStatusErrors] = useState(null);
  
  const [openUpdateStatusDialog, setOpenUpdateStatusDialog] = useState(false);
  const [updateStatusErrors, setUpdateStatusErrors] = useState(null);
  const [updatedStatus, setUpdatedStatus] = useState([]);

  const [openDeleteStatusConfirmDialog, setOpenDeleteStatusConfirmDialog] = useState(false);
  const [deletedStatusId, setdeletedStatusId] = useState();

  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [circularProgress, setCircularProgress] = useState(false);

  const getStatus = async () => {
    setCircularProgress(true);
    const url = `${getEnvironement().API_URL}/admin/status`;
    const params = {managerId: user.id};
    await axios.get(url, {params})
      .then((response) => {
        setAllStatus(response.data);
        setStatus(response.data);
        if (response.data.length == 0) {
          setIsEmptyStatus(true);
        } else {
          setIsEmptyStatus(false);
        }
      })
      .then(() => setCircularProgress(false))
      .catch((err) => {
        console.log(err);
      });

  };

  const getSelectedStatus = async (id) => {
    setOpenBackDrop(true);
    const url = `${getEnvironement().API_URL}/admin/status/${id}`;
    await axios.get(url)
      .then((response) => {
        setUpdatedStatus(response.data);
      })
      .then(() => setOpenBackDrop(false))
      .then(() => setOpenUpdateStatusDialog(true))
      .catch((err) => {
        console.log(err);
      });
  };

  const addStatus = async (status) => {
    const url = `${getEnvironement().API_URL}/admin/status`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await axios.post(url, {managerId: user.id, ...status}, config)
      .then(() => setAddStatusErrors(null))
      .then(() => setOpenAddStatusDialog(false))
      .then(() => getStatus())
      .then(() => {
        toast.success('Le status a bien été ajouté', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      })
      .catch((err) => {
        setAddStatusErrors(err.response.data.errors);
      });
  };

  const updateStatus = async (selectedStatus) => {
    console.log(selectedStatus);
    const url = `${getEnvironement().API_URL}/admin/status/${selectedStatus.id}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await axios.put(url, selectedStatus, config)
      .then(() => setUpdateStatusErrors(null))
      .then(() => setOpenUpdateStatusDialog(false))
      .then(() => getStatus())
      .then(() => {
        toast.success('Le status a bien été modifié', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      })
      .catch((err) => {
        console.log(err);
        setUpdateStatusErrors(err.response.data.errors);
      });
  };

  const deleteStatus = async (idStatus) => {
    setCircularProgress(true);
    const url = `${getEnvironement().API_URL}/admin/status/${idStatus}`;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    await axios.delete(url, config)
      .then(() => getStatus())
      .then(() => setCircularProgress(false))
      .then(() => {
        toast.success('Le status a bien été supprimé', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      })
      .catch(() => {
        toast.error('Merci de supprimer tous les produits liés à ce status avant de le supprimer', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
        getStatus();
        setCircularProgress(false);
      });
  };

  const handelChangeSearchInput = (e) => {
    setStatus(allStatus.filter(item => item.status.startsWith(e.target.value)));
  };

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <div className='py-4'>
      <ToastContainer />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AddStatusDialog
        open={openAddStatusDialog}
        onClose={() => {setOpenAddStatusDialog(false); setAddStatusErrors(null);}}
        onClickAddStatus={addStatus}
        errors={addStatusErrors}
      />
      <UpdateStatusDialog
        open={openUpdateStatusDialog}
        onClose={() => {setOpenUpdateStatusDialog(false); setUpdateStatusErrors(null);}}
        onClickUpdateStatus={updateStatus}
        selectedStatus={updatedStatus}
        errors={updateStatusErrors}
      />
      <DeleteStatusConfirmDialog
        open={openDeleteStatusConfirmDialog}
        onClose={() => setOpenDeleteStatusConfirmDialog(false)}
        onClickDeleteStatus={() => {
          deleteStatus(deletedStatusId);
          setOpenDeleteStatusConfirmDialog(false);
        }}
      />
      <AdminHeader name={user.nom} />
      <div className='flex justify-between items-center mb-5'>
        <p className='font-poppins font-medium text-2xl'>Tous les status</p>
        <Button
          variant='contained'
          onClick={() => setOpenAddStatusDialog(true)}
        >
          + Ajouter un nouveau status
        </Button>
      </div>
      <div className='flex w-1/3 h-10 p-2 mb-5 bg-[#FAFAFA] rounded-lg'>
        <img src={searchIcon}/>
        <input
          type='search'
          placeholder='Chercher par status'
          onChange={(e) => handelChangeSearchInput(e)}
          className='bg-[#FAFAFA] ml-2 p-2 focus:outline-none w-full'
        />
      </div>
      <StatusTable
        header={[
          'Id_status',
          'status',
          'Date',
          'Actions'
        ]}
        data={status}
        onClickUpdateStatus={(idStatus) => getSelectedStatus(idStatus)}
        onClickDeleteStatus={(idStatus) => {
          setOpenDeleteStatusConfirmDialog(true);
          setdeletedStatusId(idStatus);
        }}
      />
      {isEmptyStatus && (
        <div className='rounded border border-dashed border-1 opacity-50 p-5 m-5 text-center font-poppins font-normal text-sm/[16px] w-60vw'>Aucun status</div>
      )}
      {circularProgress && (
        <div className='flex justify-center p-5'>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
