import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, userSelector } from '../../../../store/userSlice';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import BottomBar from './bottomBar';
import { getLang } from '../../../../environnement';

function AdminSidebar({ isCollapsed, handleToggle, handleTabClick, activeTab }) {
  const user = useSelector(userSelector);
  const [lang] = useState('FR');
  const dispatch = useDispatch();
  const buttonClasses = 'flex items-center p-4 focus:outline-none transition-colors duration-200';

  const tabs = user.role_id === 1
    ? [
      { name: 'dashboard', icon: <DashboardOutlinedIcon fontSize='small'/>, label: getLang(lang).sideBar.dashboard },
      { name: 'clients', icon: <GroupOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.clients },
      { name: 'managers', icon: <ManageAccountsOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.managers },
      { name: 'packs', icon: <Inventory2OutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.packs },
      { name: 'status', icon: <MessageOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.status },
      { name: 'profile', icon: <AccountBoxOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.profile },
    ]
    : [
      { name: 'dashboard', icon: <DashboardOutlinedIcon fontSize='small'/>, label: getLang(lang).sideBar.dashboard },
      { name: 'clients', icon: <GroupOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.clients },
      { name: 'status', icon: <MessageOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.status },
      { name: 'profile', icon: <AccountBoxOutlinedIcon fontSize='small' />, label: getLang(lang).sideBar.profile },
    ];

  return (
    <>
      <div className={`hidden md:flex flex-col justify-between ${isCollapsed ? 'w-24' : 'w-64'} h-screen text-black transition-width duration-300 bg-white overflow-hidden shadow-md`}>
        <button onClick={handleToggle} className="p-4 focus:outline-none">
          {isCollapsed
            ? (
              <div className='p-4 rounded-xl'>
                <MenuOutlinedIcon fontSize='small' />
              </div>
            )
            : (
              <div className='p-4 rounded-xl'>
                <CloseOutlinedIcon fontSize='small' />
              </div>
            )
          }
        </button>
        <nav className="flex flex-col justify-center w-full">
          {tabs.map(tab => (
            <button
              key={tab.name}
              onClick={() => handleTabClick(tab.name)}
              className={buttonClasses}
            >
              <div className={`flex items-center ${isCollapsed ? 'justify-start w-full' : 'justify-start w-full'}`}>
                <div className="w-5 flex-shrink-0 flex justify-center">
                  {activeTab === tab.name && (
                    <div className="w-1 h-1 rounded-full bg-primary"></div>
                  )}
                </div>
                {tab.icon}
                {!isCollapsed && <span className="ml-2">{tab.label}</span>}
              </div>
            </button>
          ))}
        </nav>
        <div className='flex flex-col justify-center items-center py-5'>
          <button onClick={() => {
            const confirmLogout = window.confirm(getLang(lang).sideBar.confirmLogout);
            if (confirmLogout) {
              dispatch(setUser(null));
            }
          }}>
            <LogoutOutlinedIcon fontSize='small'/>
          </button>
        </div>
      </div>
      <BottomBar activeTab={activeTab} handleTabClick={handleTabClick} />
    </>
  );
}

export default AdminSidebar;
