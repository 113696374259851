import React from 'react';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import LegalStationLandingView from './views/legalStationLanding/legalStationLandingView';
import Apropos from './views/Apropos/AproposView';
import CreationEntreprise from './views/creationEntreprise/creationEntreprise';
import Contact from './views/contact/contact';
import ContartTravail from './views/products/contratViews/contratTravail/contratTravail';
import InfoContartTravail from './views/products/contratViews/contratTravail/infoContratTravail/infoContratTravail';
import Cdi from './views/products/contratViews/contratTravail/cdi/cdi';
import Comptabilite from './views/products/domiciliationComptabilteViews/comptabilite/comptabilite';
import InfoComptabilite from './views/products/domiciliationComptabilteViews/comptabilite/infoComptabilite/infoComptabilite';
import ContartLocation from './views/products/contratViews/contratLocation/contratLocation';
import InfoContartLocation from './views/products/contratViews/contratLocation/infoContratLocation/infoContratLocation';
import ContratPrestation from './views/products/contratViews/contratPrestation/contratPrestation';
import InfoContratPrestation from './views/products/contratViews/contratPrestation/infoContartPrestation/infoContratPrestation';
import BailCommercial from './views/products/contratViews/contratLocation/bailCommercial/bailCommercial';
import Inscription from './views/log/inscription/inscription';
import Login from './views/log/login/login';
import Dashboard from './views/dashboard/dashboard';
import Sarl from './views/products/creationEntreprise/sarl/sarl';
import Association from './views/products/creationEntreprise/association/association';
import BailLocationNonMeublee from './views/products/contratViews/contratLocation/bailLocationNonMeublee/bailLocationNonMeublee';
import AutoEntreprise from './views/products/creationEntreprise/auto-entrepreneur/autoEntreprise';
import Mystatus from './views/products/creationEntreprise/monstatuts/my_status';
import ContratCGV from './views/products/contratViews/contratCGV/contratCGV';
import BailLocationMeublee from './views/products/contratViews/contratLocation/bailLocationMeublee/bailLocationMeublee';
import Cdd from './views/products/contratViews/contratTravail/cdd/cdd';
import BailProfessionnel from './views/products/contratViews/contratLocation/bailProfessionnel/bailProfessionnel';
import Cse from './views/products/AutresDemarches/CseFormations/Cse';
import Cession from './views/products/AutresDemarches/cession/cession';
import Dissolution from './views/products/AutresDemarches/dissolution/dissolution';
import CarteGrise from './views/products/AutresDemarches/CarteGrise/CarteGrise';
import ChangeAdresse from './views/products/AutresDemarches/ChangementAdresse/ChangeAdresse';
import Domiciliation from './views/products/domiciliationComptabilteViews/domiciliation/domicialisation';
import InfoDomiciliation from './views/products/domiciliationComptabilteViews/domiciliation/infoDomicialisation/infoDomiciliation';
import CreationEntrepriseLayout from './views/products/creationEntreprise/creationEntrepriseLayout';
import SarlForm from './views/products/creationEntreprise/sarl/sarlForm';
import DomiciliationForm from './views/products/domiciliationComptabilteViews/domiciliation/domiciliationForm/domiciliationForm';
import MonStatusForm from './views/products/creationEntreprise/monstatuts/monStatusForm';
import ComptabiliteForm from './views/products/domiciliationComptabilteViews/comptabilite/comptabiliteForm/ComptabiliteForm';
import FormeJuridique from './views/products/FormeJuridique/FormeJuridique';
import AutoEntrepriseForm from './views/products/creationEntreprise/auto-entrepreneur/autoEntrepriseForm';
import FormContratTravail from './views/products/contratViews/contratTravail/formContratTravail/formContratTravail';
import ContratPrestationForm from './views/products/contratViews/contratPrestation/ContratPrestationForm/contratPrestationForm';
import AssociationForm from './views/products/creationEntreprise/association/associationForm';
import CarteGriseForm from './views/products/AutresDemarches/CarteGrise/CarteGriseForm';
import DissolutionForm from './views/products/AutresDemarches/dissolution/dissolutionForm';
import CessionForm from './views/products/AutresDemarches/cession/cessionForm';
import ChangementStatutForm from './views/products/AutresDemarches/ChangementAdresse/changeStatutForm';
import PacteAssociesForm from './views/products/contratViews/pacteAssocies/pacteAssociesForm/pacteAsocciesForm';
import InfoPacteAssocies from './views/products/contratViews/pacteAssocies/infoPacteAssocies';
import PacteAssocies from './views/products/contratViews/pacteAssocies/pacteAssocies';
import InfoBailCommercial from './views/products/contratViews/contratLocation/bailCommercial/infoBailCommercial';
import BailCommercialForm from './views/products/contratViews/contratLocation/bailCommercial/bailCommercialForm/bailCommercialForm';
import BailLocationMeubleeForm from './views/products/contratViews/contratLocation/bailLocationMeublee/bailLocationMeubleeForm/BailLocationMeubleeForm';
import PacteAssocieFormSas from './views/products/contratViews/pacteAssocies/pacteAssociesForm/pacteAssocieFormSas';
import InfoContratCGV from './views/products/contratViews/contratCGV/infoContratCGV';
import ContratCGVForm from './views/products/contratViews/contratCGV/contratCGVForm/contratCGVForm';
import AdminLogin from './views/admin/login';
import AdminPage from './views/admin/utils/middleware';
import Admin from './views/admin/utils/components/admin';
import DetailsClient, {loader as clientLoader} from './views/admin/detailsClient';
import Paiement from './views/products/paiement';
import DetailsPack from './views/dashboard/detailsPack';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <LegalStationLandingView />,
  },
  {
    path: '/inscription',
    element: <Inscription />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/apropos',
    element: <Apropos />,
  },
  {
    path: '/creationEntreprise',
    element: <CreationEntreprise />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
  },

  // contrat ----------------------------------------------------------------------------
  {
    path: '/contratTravail',
    element: <ContartTravail />,
    children: [
      {
        index: true,
        element: <InfoContartTravail />,
      },
      {
        path: 'cdi',
        children: [
          {
            index: true,
            element: <Cdi />,
          },
          {
            path: 'form',
            element: <FormContratTravail typeContart="cdi" />,
          },
        ],
      },
      {
        path: 'cdd',
        children: [
          {
            index: true,
            element: <Cdd />,
          },
          {
            path: 'form',
            element: <FormContratTravail typeContart="cdd" />,
          },
        ],
      },
    ],
  },
  {
    path: '/contratLocation',
    element: <ContartLocation />,
    children: [
      {
        index: true,
        element: <InfoContartLocation />,
      },
      {
        path: 'bailCommercial',
        element: <BailCommercial />,
        children: [
          {
            index: true,
            element: <InfoBailCommercial />,
          },
          {
            path: 'form',
            element: <BailCommercialForm />,
          },
        ],
      },
      {
        path: 'bailLocationNonMeublee',
        element: <BailLocationNonMeublee />,
      },
      {
        path: 'bailLocationMeublee',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <BailLocationMeublee />,
          },
          {
            path: 'form',
            element: <BailLocationMeubleeForm />,
          },
        ],
      },
      {
        path: 'bailProfessionnel',
        element: <BailProfessionnel />,
      },
    ],
  },

  {
    path: '/contratPrestation',
    element: <ContratPrestation />,
    children: [
      {
        index: true,
        element: <InfoContratPrestation />,
      },
      {
        path: 'form',
        element: <ContratPrestationForm />,
      },
    ],
  },
  {
    path: '/PacteAssocies',
    element: <PacteAssocies />,
    children: [
      {
        index: true,
        element: <InfoPacteAssocies />,
      },
      {
        path: 'form',
        element: <PacteAssociesForm />,
      },
      {
        path: 'formSas', // New route for PacteAssocieFormSas
        element: <PacteAssocieFormSas />, // Component to render when accessing /PacteAssocies/formSas
      },
    ],
  },
  {
    path: '/contratCGV',
    element: <ContratCGV />,
    children: [
      {
        index: true,
        element: <InfoContratCGV />,
      },
      {
        path: 'form',
        element: <ContratCGVForm />,
      },
    ],
  },

  // autres démarches ------------------------------------------------------------------
  {
    path: '/cseFormation',
    element: <Cse />,
  },
  {
    path: '/carteGrise',
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <CarteGrise />,
      },
      {
        path: 'form',
        element: <CarteGriseForm />,
      },
    ],
  },
  {
    path: '/changementStatut',
    element: <ChangeAdresse />,
  },
  {
    path: '/changementStatut',
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <ChangeAdresse />,
      },
      {
        path: 'form',
        element: <ChangementStatutForm />,
      },
    ],
  },
  {
    path: '/cession',
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <Cession />,
      },
      {
        path: 'form',
        element: <CessionForm />,
      },
    ],
  },
  {
    path: '/dissolution',
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <Dissolution />,
      },
      {
        path: 'form',
        element: <DissolutionForm />,
      },
    ],
  },

  // comptabilité et domiciliation --------------------------------------------------------
  {
    path: '/comptabilite',
    element: <Comptabilite />,
    children: [
      {
        index: true,
        element: <InfoComptabilite />,
      },
      {
        path: 'form',
        element: <ComptabiliteForm />,
      },
      {
        path: 'CreationComForm',
        element: <FormeJuridique />,
      },
    ],
  },
  {
    path: '/domiciliation',
    element: <Domiciliation />,
    children: [
      {
        index: true,
        element: <InfoDomiciliation />,
      },
      {
        path: 'form',
        element: <DomiciliationForm />,
      },
      {
        path: 'CreationDomForm',
        element: <FormeJuridique />,
      },
    ],
  },

  // création entreprise -----------------------------------------------------------------
  {
    path: '/maSociete',
    element: <CreationEntrepriseLayout />,
    children: [
      {
        index: true,
        element: <Sarl />,
      },
      {
        path: 'form',
        element: <SarlForm />,
      },
    ],
  },
  {
    path: '/myStatus',
    element: <CreationEntrepriseLayout />,
    children: [
      {
        index: true,
        element: <Mystatus />,
      },
      {
        path: 'form',
        element: <MonStatusForm />,
      },
    ],
  },
  {
    path: '/autoEntreprise',
    element: <CreationEntrepriseLayout />,
    children: [
      {
        index: true,
        element: <AutoEntreprise />,
      },
      {
        path: 'form',
        element: <AutoEntrepriseForm />,
      },
    ],
  },
  {
    path: '/association',
    element: <CreationEntrepriseLayout />,
    children: [
      {
        index: true,
        element: <Association />,
      },
      {
        path: 'form',
        element: <AssociationForm />,
      },
    ],
  },
  {
    path: '/paiement',
    element: <Paiement />,
  },
  {
    path: 'products',
    element: <DetailsPack />,
  },

  // admin ----------------------------------------------------------------------------
  {
    path: '/admin',
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <AdminPage page={<Admin />} />,
      },
      {
        path: 'login',
        element: <AdminPage page={<AdminLogin />} />,
      },
      {
        path: 'client/:clientId',
        element: <AdminPage page={<DetailsClient />} />,
        loader: clientLoader,
      }
    ],
  },
]);