/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import Box from '../../assets/icons/box.png';
import BoxRemove from '../../assets/icons/box-remove.png';
import BoxTime from '../../assets/icons/box-time.png';
import BoxTick from '../../assets/icons/box-tick.png';
import userIcon from '../../assets/icons/usericon.png';
import EmptyWalletTick from '../../assets/icons/empty-wallet-tick.png';
import getEnvironement from '../../environnement';
import { userSelector } from '../../store/userSlice';
import AdminHeader from './utils/components/header';
import AdminChart from './utils/components/chart';

function ColoredRectangle({
  rectangleColor, hoverColor, icon, number, text, isCircularProgress = true
}) {
  return (
    <div className='flex gap-4 min-w-32 items-center'>
      <div className={`cursor-pointer flex flex-col items-center justify-center rounded-lg w-[52px] h-[52px] min-w-[52px] min-h-[52px] 
        ${rectangleColor} hover:${hoverColor}`}>
        <img src={icon} alt="Icon" className="w-8" />
      </div>
      <div>
        <div className="text-lg font-bold">
          {isCircularProgress && <CircularProgress size={15} color='secondary'/>}
          {!isCircularProgress && number}
        </div>
        <div className="text-sm w-44">{text}</div>
      </div>
    </div>
  );
}


export default function AdminDashboard() {

  const user = useSelector(userSelector);

  const [isCircularProgress, setIsCircularProgress] = useState(true);
  const [data, setData] = useState({});

  const rectanglesData = user.role_id === 1 
    ? [
      { id: 1, rectangleColor: 'bg-gradient', hoverColor: 'bg-red-300', icon: Box , number: data.products, text: 'Nombre de produits enregistrés' },
      { id: 2, rectangleColor: 'bg-staticyellow', hoverColor: 'bg-indigo-300', icon: BoxTick, number: data.productsValidés, text: 'Nombre de produits validés' },
      { id: 3, rectangleColor: 'bg-staticpink', hoverColor: 'bg-purple-300', icon: BoxTime, number: data.productsEnCoursTraitement, text: 'Nombre de produits en cours de traitement' },
      { id: 4, rectangleColor: 'bg-staticred', hoverColor: 'bg-pink-300', icon: BoxRemove , number: data.productsDemandeAnnulation, text: 'Nombre de produits avec une demande d\'annulation' },
      { id: 5, rectangleColor: 'bg-staticpink', hoverColor: 'bg-blue-300', icon: userIcon, number: data.clients, text: 'Nombre de clients' },
      { id: 6, rectangleColor: 'bg-staticgreen', hoverColor: 'bg-green-300', icon: userIcon , number: data.managers, text: 'Nombre de géstionnaires' },
      { id: 7, rectangleColor: 'bg-staticpurple', hoverColor: 'bg-yellow-300', icon: Box , number: data.packs, text: 'Nombre de packs achetés' },
      { id: 8, rectangleColor: 'bg-primary', hoverColor: 'bg-gray-300', icon: EmptyWalletTick , number: data.revenu, text: 'Montant total payée' },
    ]
    : [
      { id: 1, rectangleColor: 'bg-gradient', hoverColor: 'bg-blue-300', icon: userIcon, number: data.clients, text: 'Nombre de clients' },
      { id: 2, rectangleColor: 'bg-staticyellow', hoverColor: 'bg-indigo-300', icon: BoxTick, number: data.productsValidés, text: 'Nombre de produits validés' },
      { id: 3, rectangleColor: 'bg-staticpink', hoverColor: 'bg-purple-300', icon: BoxTime, number: data.productsEnCoursTraitement, text: 'Nombre de produits en cours de traitement' },
      { id: 4, rectangleColor: 'bg-staticred', hoverColor: 'bg-pink-300', icon: BoxRemove , number: data.productsDemandeAnnulation, text: 'Nombre de produits avec une demande d\'annulation' },
    ];

  const getDashboardData = async () => {
    const url = `${getEnvironement().API_URL}/admin/dashboard`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await axios.post(url, {managerId: user.id}, config)
      .then((response) => setData(response.data))
      .then(() => setIsCircularProgress(false))
      .catch(() => {
        setIsCircularProgress(false);
      });
  };

  useEffect(() => {
    getDashboardData();
  }, []);
  
  return (
    <div className='py-4 h-screen flex flex-col'>
      <div className='h-auto'>
        <AdminHeader name={user.nom} />
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {rectanglesData.map(rectangle => (
            <ColoredRectangle
              key={rectangle.id}
              rectangleColor={rectangle.rectangleColor}
              hoverColor={rectangle.hoverColor}
              icon={rectangle.icon}
              number={rectangle.number}
              text={rectangle.text}
              isCircularProgress={isCircularProgress}
              onClick={() => console.log(`Clicked on ${rectangle.text}`)}
            />
          ))}
        </div>
      </div>
      <div className='mt-10 flex flex-col lg:flex-row gap-4 flex-1'>
        <div className='sm:w-full md:w-1/2 space-y-4'>
          <p className='font-bold text-balance'>Nombre de clients par mois (en {new Date().getFullYear()}):</p>
          <AdminChart data={data.usersPerMonth} />
        </div>

        <div className='sm:w-full md:w-1/2 space-y-4'>
          <p className='font-bold text-balance'>{user.role_id === 1 ? 'Paiement' : 'Nombre de produits'} par mois (en {new Date().getFullYear()}):</p>
          <AdminChart data={user.role_id === 1 ? data.revenusPerMonth : data.productsPerMonth} />
        </div>
      </div>

    </div>
  );
}