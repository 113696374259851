// BottomBar.js
import React from 'react';
import DashboardIcon from '../../assets/icons/DashboardIcon.png';
import ProfileIcon from '../../assets/icons/ProfileIcon.png';
import PaymentsIcon from '../../assets/icons/PaymentsIcon.png';
import RecordsIcon from '../../assets/icons/RecordsIcon.png';
import ContactIcon from '../../assets/icons/ContactIcon.png';

function BottomBar({ activeTab, handleTabClick }) {
  const tabs = [
    { name: 'dashboard', icon: DashboardIcon },
    { name: 'profile', icon: ProfileIcon },
    { name: 'payments', icon: PaymentsIcon },
    { name: 'records', icon: RecordsIcon },
    { name: 'contact', icon: ContactIcon },
  ];

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white flex justify-around shadow-md p-2 md:hidden z-20">
      {tabs.map(tab => (
        <button
          key={tab.name}
          onClick={() => handleTabClick(tab.name)}
          className="relative flex flex-col items-center p-2 focus:outline-none"
        >
          <img
            src={tab.icon}
            alt={tab.name}
            className={`h-6 w-6 ${activeTab === tab.name ? 'opacity-100' : 'opacity-50'}`}
          />
          {activeTab === tab.name && (
            <div className="absolute top-0 right-0 h-2 w-2 bg-primary rounded-full"></div>
          )}
        </button>
      ))}
    </div>
  );
}

export default BottomBar;
